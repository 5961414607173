import { DateTime } from 'luxon'

export const udTypesIds: Record<iUdTypes, number> = {
  // 'Season': 1,
  // 'Superflex': 2,
  // 'Eliminator Season': 3,
  // 'Weekly Winners': 4,
  // 'Marathon': 5,
  // 'Sprint': 6,
  // 'Pre Draft': 7,
  // 'Season Final': 8,
  'Pre-Draft': 9,
}

export const udTypes = Object.keys(udTypesIds) as Array<iUdTypes>
export const udTypeValues = Object.values(udTypesIds) as Array<number>

export const scheduleOpponents = new Map([
  ['DET', { bye: 5, w15team: 'BUF', w16team: 'CHI', w17team: 'SF' }],
  ['LAC', { bye: 5, w15team: 'TB', w16team: 'DEN', w17team: 'NE' }],
  ['PHI', { bye: 5, w15team: 'PIT', w16team: 'WAS', w17team: 'DAL' }],
  ['TEN', { bye: 5, w15team: 'CIN', w16team: 'IND', w17team: 'JAX' }],
  ['KC', { bye: 6, w15team: 'CLE', w16team: 'HOU', w17team: 'PIT' }],
  ['LAR', { bye: 6, w15team: 'SF', w16team: 'NYJ', w17team: 'ARI' }],
  ['MIA', { bye: 6, w15team: 'HOU', w16team: 'SF', w17team: 'CLE' }],
  ['MIN', { bye: 6, w15team: 'CHI', w16team: 'SEA', w17team: 'GB' }],
  ['CHI', { bye: 7, w15team: 'MIN', w16team: 'DET', w17team: 'SEA' }],
  ['DAL', { bye: 7, w15team: 'CAR', w16team: 'TB', w17team: 'PHI' }],
  ['PIT', { bye: 9, w15team: 'PHI', w16team: 'BAL', w17team: 'KC' }],
  ['SF', { bye: 9, w15team: 'LAR', w16team: 'MIA', w17team: 'DET' }],
  ['CLE', { bye: 10, w15team: 'KC', w16team: 'CIN', w17team: 'MIA' }],
  ['GB', { bye: 10, w15team: 'SEA', w16team: 'NO', w17team: 'MIN' }],
  ['LV', { bye: 10, w15team: 'ATL', w16team: 'JAX', w17team: 'NO' }],
  ['SEA', { bye: 10, w15team: 'GB', w16team: 'MIN', w17team: 'CHI' }],
  ['ARI', { bye: 11, w15team: 'NE', w16team: 'CAR', w17team: 'LAR' }],
  ['CAR', { bye: 11, w15team: 'DAL', w16team: 'ARI', w17team: 'TB' }],
  ['NYG', { bye: 11, w15team: 'BAL', w16team: 'ATL', w17team: 'IND' }],
  ['TB', { bye: 11, w15team: 'LAC', w16team: 'DAL', w17team: 'CAR' }],
  ['ATL', { bye: 12, w15team: 'LV', w16team: 'NYG', w17team: 'WAS' }],
  ['BUF', { bye: 12, w15team: 'DET', w16team: 'NE', w17team: 'NYJ' }],
  ['CIN', { bye: 12, w15team: 'TEN', w16team: 'CLE', w17team: 'DEN' }],
  ['JAX', { bye: 12, w15team: 'NYJ', w16team: 'LV', w17team: 'TEN' }],
  ['NO', { bye: 12, w15team: 'WAS', w16team: 'GB', w17team: 'LV' }],
  ['NYJ', { bye: 12, w15team: 'JAX', w16team: 'LAR', w17team: 'BUF' }],
  ['BAL', { bye: 14, w15team: 'NYG', w16team: 'PIT', w17team: 'HOU' }],
  ['DEN', { bye: 14, w15team: 'IND', w16team: 'LAC', w17team: 'CIN' }],
  ['HOU', { bye: 14, w15team: 'MIA', w16team: 'KC', w17team: 'BAL' }],
  ['IND', { bye: 14, w15team: 'DEN', w16team: 'TEN', w17team: 'NYG' }],
  ['NE', { bye: 14, w15team: 'ARI', w16team: 'BUF', w17team: 'LAC' }],
  ['WAS', { bye: 14, w15team: 'NO', w16team: 'PHI', w17team: 'ATL' }],
])

export function getTeamByeWeek(team: string) {
  const schedule = scheduleOpponents.get(team)
  return schedule ? schedule.bye : 0
}
export function getUdTypeName(type: number): iUdTypes {
  return udTypes[type - 1]
}

export async function fetchUnderdogIds(scoring: number) {
  return await useBppSimpleFetch<Array<{ id_ud: string, id_udc: string, id_sleeper: string }>>(
    '/ud/ids',
    { query: { scoring } },
    { auth: false, errors: true },

  )
}

export async function fetchUnderdogPlayerAdp(id_player: string, scoring: number) {
  return await useBppSimpleFetch<Array<iPlayerUdAdp>>(
    '/ud/adp/player',
    { query: { id_player, scoring } },
    { auth: false, errors: true },
  )
}

export async function fetchUnderdogAdp(scoring: number) {
  const adpData = await useBppSimpleFetch<Array<iPlayerUd>>(
    '/ud/adp',
    { query: { scoring } },
    { auth: false, errors: true },
  )
  usePlayerDataStore().patchPlayerMetrics(adpData, ['id_pff', 'id_sleeper', 'id_pos', 'id_firstname', 'id_lastname', 'draft_year', 'draft_round', 'phy_age', 'id_team'], 'id_ud')
  patchUdAdpMetrics(adpData)
  return adpData.sort(sortByAttribute('ud_adp', 'asc'))
}

export function patchUdPlayerMetrics(
  dataset: iPlayer[],
  adpDataset: iPlayerUd[],
  metrics: (keyof iPlayerUd)[] = ['id_ud', 'ud_adp_value', 'ud_adp', 'ud_projection_ppg', 'ud_projection'],
  origin: iPlayerStringOrigin = 'id_sleeper',
) {
  const playerMap = new Map<string, iPlayerUd>(adpDataset.map(p => [p[origin]!, p]))
  for (const player of dataset) {
    const { [origin]: playerId } = player
    if (!playerId)
      continue
    const metricsData: Partial<iPlayerUd> | undefined = playerMap.get(playerId)
    if (!metricsData)
      continue
    for (const metric of metrics) {
      if (Object.prototype.hasOwnProperty.call(metricsData, metric)) {
        const value = metricsData[metric]
        if (value !== undefined)
          (player[metric] as typeof value) = value
      }
    }
  }
}

export async function patchUdBaseIds(dataset: Array<iUdPickRaw>, idMap: Map<string, { id_ud: string, id_sleeper: string }>) {
  return dataset.map((pick) => {
    const { id_udc, ...rest } = pick
    const { id_ud, id_sleeper } = idMap.get(id_udc) || {}
    if (!id_ud)
      throw new Error('Ids not found, try contacting support@dynastydatalab.com')
    return { id_ud, id_udc, id_sleeper, ...rest }
  })
}

const rostershipDict: iCsvParserDefinition = {
  'Picked At': { name: 'pick_date', type: 'date' },
  'Pick Number': { name: 'pick_no', type: 'number' },
  'Appearance': { name: 'id_udc', type: 'string' },
  'Draft': { name: 'id_draft', type: 'string' },
  'Draft Size': { name: 'teams', type: 'number' },
  'Tournament': { name: 'id_tournament', type: 'string' },
  'Tournament Title': { name: 'tournament_name', type: 'string' },
  'Tournament Entry Fee': { name: 'tournament_fee', type: 'number' },
  'Tournament Total Prizes': { name: 'tournament_prize', type: 'number' },
  'Tournament Size': { name: 'tournament_size', type: 'number' },
}

export async function parseUdPicks(data: unknown[]): Promise<Array<iUdPick>> {
  const dataset = transformCsvDataset<iUdPickRaw>(data, rostershipDict)
  const activeType = getUnderdogRosterType(dataset)
  await useUdStore().checkIdsData(activeType)
  return patchUdBaseIds(dataset, useUdStore().getUdIds)
}

export function parseUdRosterPlayers(dataset: Array<iUdPick>): Array<iPlayerUdRoster> {
  const playerGroups = groupByAttribute(dataset, 'id_ud')
  const drafts = new Set(dataset.map(d => d.id_draft))
  const totalLeagues = drafts.size
  const PlayerDataStore = usePlayerDataStore()
  const players = []
  const maxCount = Math.max(...Object.values(playerGroups).map(group => group.length))

  for (const id in playerGroups) {
    const { pick_date, pick_no, id_draft, tournament_fee, tournament_prize, tournament_size, ...rest } = playerGroups[id][0]
    const playerData = PlayerDataStore.getPlayerData(id, 'id_ud')
    if (!playerData)
      continue
    const playerGroup = playerGroups[id]
    const count = playerGroup.length
    const percValue = normalizeValue(count, 0, maxCount, true)
    players.push({
      ...playerData,
      ...rest,
      roster_count: count,
      roster_count_perc: percValue,
      roster_per: roundNumber((count / totalLeagues), 4),
      roster_per_perc: percValue,
      roster_leagues: playerGroup.map(p => p.id_draft),
      roster_dates: playerGroup.map(p => p.pick_date),
      roster_picks: playerGroup.map(p => p.pick_no),
      roster_fees: playerGroup.map(p => p.tournament_fee).reduce((a, b) => a + b, 0),
    })
  }

  return players
}

export function mergeUdRostershipAdpData(rosterData: iPlayerUdRoster[], adpData: iPlayerUd[]): iPlayerUdRosterAdp[] {
  const playerMap = new Map(rosterData.map(player => [player.id_ud, player]))
  return adpData.map((player) => {
    const rosterPlayer = playerMap.get(player.id_ud)
    if (!rosterPlayer) {
      return {
        ...player,
        id_udc: '',
        roster_count: 0,
        roster_count_perc: 0,
        roster_per: 0,
        roster_per_perc: 0,
        roster_leagues: [],
        roster_dates: [],
        roster_picks: [],
        roster_fees: 0,
      }
    }
    return { ...player, ...rosterPlayer }
  })
}

const udPlayerIdsSample: Record<iUdTypes, string[]> = {
  // 'Season': ['9e801ec6-ed8e-49b1-8b66-90553d7dd7e2', '098d87b9-1fc9-4466-a7dd-7f35f697f506', 'a36d312f-0f65-4e3a-98ef-823608309b21', '5a9b6074-7296-4571-94f8-6b9e803ba039', '8209c653-34c2-4bc5-8434-d5a6d2c8b27b', '7b36b9f5-93c3-4fb7-8703-f89aa740c452', '7f4475be-2cd2-4e94-9272-9d6e1c6a6262', '3f671138-3ef9-437b-af4b-f865c1423161', 'fb1781a7-ebfc-47c4-a925-18ad114c9834', '2bad3d34-9960-4d9a-8fa7-6abc8d2ba1f7', 'c76953d5-dc07-49f6-85e2-731b919c09a0', 'f098bc7a-7b26-4b95-8a2a-5603e0c79eab', 'ffadf9c7-84c5-4cbd-882c-08ac77e4ceff', '56c751bc-45b5-4984-b916-8a9b1cbc056e', 'b4792419-5e76-45de-864a-741ac6df7651', '333093b5-7ef3-49ae-a736-93217e79f666', 'c3032705-9198-4d1b-ba45-3d5eb7983645', '5b733acd-4a48-40f0-9662-51e4540b0351', '6591b1cf-50ee-4c6d-964c-2b26ad46bfde', '0ca934b7-a6df-4df8-9f7b-f34f1f51d396', 'bc1959be-6b30-4eca-be97-0f2e5b97eb9a', '0eca149b-3acb-4540-90b9-e573a3983e73', '8da0dbe1-4d06-45f3-830a-2112443c2466', '47a7e2a4-2f23-4670-ac09-1c20b7e4bb39'],
  // 'Superflex': ['993f61f4-bc95-445f-9762-528af1e2640e', '52056b65-ef99-4611-aa11-563718b04568', '8b9107c6-7ff6-433e-bbda-a0170a400bd9', 'f74d24f1-fa84-424e-8d9b-517e28e3bc60', '624b70c3-2a66-4b06-b3bc-11ffb8a14adc', '5884f810-eacf-4ac3-9184-982d53c78337', '41364a94-7336-4f35-96cd-1334f90815cf', '1652b394-d6d2-42cc-8b98-15a1420e90f7', '213a0b31-546a-458d-98fc-c317381b6352', '8c8b7f25-606c-4ac2-b0dd-8487ae1360f1', '2b5dc894-d280-4eeb-a39f-5cdb11b95c65', '03462147-4de5-4328-9a21-b88532c5d8eb', 'd6352f85-bc55-4cdb-89b4-244a8e3d64ca', 'e76e414d-57ae-422e-88f6-abee30e2be17', '0af4add6-8934-482a-8993-f12c7f2b5478', '4c41bc2d-51ef-4494-95a2-21fb970693d0', '51b1506c-e64f-4d46-bc17-40515c1bb832', '491efce2-11d2-4016-8ef1-7c146fdba66a', '0b0ce648-6a8c-493f-88b3-c9b61d998b04', '23a047a7-8ddc-47b2-9eeb-dab1d39646f0', '82d7a339-ebf7-4142-9019-1097fca1a32a', '875386e1-9649-42b7-b70f-1e6a64d60246', 'ab9c5603-6c09-4fa1-bf31-2ac7d973028e', '0f9af603-5d4a-4410-bddc-81a87dd25934'],
  // 'Eliminator Season': ['9e801ec6-ed8e-49b1-8b66-90553d7dd7e2', '098d87b9-1fc9-4466-a7dd-7f35f697f506', 'a36d312f-0f65-4e3a-98ef-823608309b21', '5a9b6074-7296-4571-94f8-6b9e803ba039', '8209c653-34c2-4bc5-8434-d5a6d2c8b27b', '7b36b9f5-93c3-4fb7-8703-f89aa740c452', '7f4475be-2cd2-4e94-9272-9d6e1c6a6262', '3f671138-3ef9-437b-af4b-f865c1423161', 'fb1781a7-ebfc-47c4-a925-18ad114c9834', '2bad3d34-9960-4d9a-8fa7-6abc8d2ba1f7', 'c76953d5-dc07-49f6-85e2-731b919c09a0', 'f098bc7a-7b26-4b95-8a2a-5603e0c79eab', 'ffadf9c7-84c5-4cbd-882c-08ac77e4ceff', '56c751bc-45b5-4984-b916-8a9b1cbc056e', 'b4792419-5e76-45de-864a-741ac6df7651', '333093b5-7ef3-49ae-a736-93217e79f666', 'c3032705-9198-4d1b-ba45-3d5eb7983645', '5b733acd-4a48-40f0-9662-51e4540b0351', '6591b1cf-50ee-4c6d-964c-2b26ad46bfde', '0ca934b7-a6df-4df8-9f7b-f34f1f51d396', 'bc1959be-6b30-4eca-be97-0f2e5b97eb9a', '0eca149b-3acb-4540-90b9-e573a3983e73', '8da0dbe1-4d06-45f3-830a-2112443c2466', '47a7e2a4-2f23-4670-ac09-1c20b7e4bb39'],
  // 'Weekly Winners': ['53205260-6f94-4146-a5f1-49059618a01e', 'a73b0328-3753-4257-a42f-2024f65c6299', '9cd19921-7222-43c3-aff1-477f08344512', '4e5d4e65-9c33-45ef-b78a-0dbb0c120919', 'caa6df11-8ee9-4c56-b24f-f91f658dd630', 'c449376a-5430-443a-b27f-fc4e92a46a55', '86fb054f-f145-49c8-b496-ad0c2700a482', '4e237ece-eccd-4a50-a530-cde877af98ef', '3c68b3cf-2756-4e44-8873-63efb0d9c5ca', '16867198-3e5a-4340-9daf-49f04bea8169', 'c4de7034-f003-4568-b351-17b0420104f1', '92fb17c9-2215-4ee6-a771-dc70955a6acb', '033317d4-1046-488d-8cfc-b7e30fc28990', 'fe3d4100-95e0-445a-9f6b-cc34a1b60ba6', '357dcb4a-ec39-4e74-9f23-6154935e74ef', 'a731b7e6-850e-4ef5-9da5-a39925fd29c3', '238bc917-97fa-493a-ae41-852001e302ac', '05ac53d1-03fe-4b2a-b7a5-878a78ae4db6', '09369f0f-52e3-42d9-846c-88214b5e612e', 'cae32de2-63f7-4732-a5a1-752d95579955', 'b1a3101f-c275-4cb5-84c5-8ee7848b4d21', '738161d1-d8a7-444d-82df-7cdf8a2e299e', 'db0cdaf9-f4a4-4048-9739-ce0ff15d4ea9', '1fab963c-ca8f-47fd-b1d8-e4a317333c25'],
  // 'Marathon': ['e7d4364e-d5bc-4914-b6b2-56c94c92f874', '608f4524-3b86-48fc-aba2-0bc8063b7e84', '92a93e95-f35a-4e3a-b8de-acad490f84e3', '7fe17a60-5df5-4d3b-91bb-40978d0b9324', 'afcf549e-34a7-4db1-a697-e8846de736e9', '1d600d5a-8bdc-4370-9795-d1e3f77643dd', 'e97c37c4-98f3-4cc6-b8d3-e3b18e4f06d1', 'a2690bf0-cb99-4971-88a0-9d94541bf7dc', '188aad89-eb65-4a32-9598-731db90dbce4', '74b2787e-d4db-48a9-b0c2-d336f2f94698', 'd686a9ce-5679-4f54-b792-2a4cb0657278', 'f159ab8a-5bac-4c3e-a5f8-e544901cd3fe', '6457c99d-4b63-4ca0-abd9-4048552d27ea', 'd6f144bd-fc22-433e-9865-00a10cd827ad', '35e761aa-df26-4960-8772-9f3aad3ccfef', 'd6f9dd9d-834d-43e1-9ebd-7ece8e2e442c', '545b3cf8-c956-4e56-b34f-326aedfd8b57', 'bbf73374-1784-488b-bf33-447548e32815', '96588cc2-d65f-4fca-9770-a17261584803', '29dd38c3-571b-4387-97b8-c4dac218db7c', '06126bb5-5ef6-4db1-8312-cb62f5f3fc3a', '7451c3f9-0c8b-4f9e-b961-ccaf0be06dd6', '9de3340f-133a-4662-8069-df81747c0124', '4082e1fe-4689-45d5-b137-b7429b21208a'],
  // 'Sprint': ['1acdb64e-7951-4ae5-8b6a-19ef9936f4c1', '0597890d-7177-4c45-84ce-6542695fade8', '0ca7b391-1f8b-4bc0-8f8f-983611631c18', 'a95406f7-30bf-4cf5-ac51-74662a3803ab', '54eefef7-5525-4bdd-bf41-344e4caf0832', '8fa0589e-6abb-4272-840e-b2ecbb1b1e91', 'b0aa0fd1-349b-4cb7-8ddf-9c379d25b0c9', 'f00c5466-b003-4b8b-b7e7-4d20574976d3', '750acbf5-e88d-4ba4-b374-e550069e60c2', '5a43f5ee-2dfc-4668-8e6c-fa27220b3cdf', 'e9bab1f0-ffb3-452a-80dd-d70f84b5dec1', 'c19fadd6-cb54-41c3-9c82-d6b261f460d9', '6a6784f2-84d1-4e28-8666-085c1b3edff0', '66c7bf82-59ff-481d-a2fa-2b971fdd2f0b', '8a374812-618c-44cd-8444-4b2b1865cd4f', '4be6bc42-7639-435f-bf6a-fba9fcbdb2dd', '478f8643-9a83-45d5-8a30-0e4acfc04951', '09d3a4ed-ab7d-4e0e-bf05-db0565b80db2', '53723f70-28a0-4a25-aaf6-08faeedf49e9', '2a12ff7d-a0be-4357-a2a9-4c8b4add965e', 'a0f3f283-9e16-4a35-a5d2-ef749fcc4059', '269a253c-f206-4664-944a-d6896c79eb07', '13d58c26-90bf-4e04-897e-624259acc1a6', 'e9577a77-16c5-49d3-bbca-73d54b6e554c'],
  // 'Pre Draft': ['44790f8d-3346-440d-88a2-891739228933', '3354e895-8aaa-4c24-ade4-d460f20a7a36', 'f755e72a-eee7-4ef7-9616-a74ba99538f8', '023cc2b9-e246-408f-9e28-3dcc23874336', '6b44c699-3ddc-44cb-90be-1f5a734464e8', '2b7e60f4-f21c-4531-82f3-cf154fd1483e', '7f32ee21-248e-45e6-ac16-8353b3220822', '59491863-9d3c-4081-8431-e0ee5116e910', 'e3b1a79d-1701-46d6-a13e-a65363c3727d', 'a6b9aba5-3c16-4096-82ee-916bb3d71a4d', 'fdbd05e0-1010-42a7-a112-0c119c6d790c', 'eb44550a-f497-4536-8df3-569ec5e74620', '0c8a3ba1-abbe-4a36-9dc2-ef1a8d290a87', 'b8c53f9d-02ef-4505-b6fb-5b3839710e8d', '9a70a362-bab2-4b50-9d0a-c60560c9e788', '15720fc8-f603-4cdc-bc60-a5016270547a', '0fad0606-9288-4548-a42b-fb46f338c07d', 'a88a11e3-6050-4fb0-9013-3858ab0b8f3e', '373627bc-9000-4e24-8f48-ae77c95361b6', '569982e1-3db4-40c9-8471-aece72fe2e65', '6ddc37c5-14ea-4515-93cd-6691278369d0', '1e12a825-4277-4cf2-98f2-6f8e5045362b', '468f85a9-75b8-4a36-9025-f5ab5b746c96', 'c9f316cd-da47-4da1-90d0-7cc7e428fdc1'],
  // 'Season Final': ['495ad85e-d98f-4c37-ae6c-97270b6df241', '7808b9b3-8661-4a09-88c5-29aa54d95f40', '64717ebc-fc80-41b5-883a-d3e29f974649', '0224677e-27a6-4e03-af0d-ab7981331159', '0ccdf701-7cf0-4d9e-b0b3-3c5bf1235615', '8a4c1e92-0fd9-4b32-9e12-e8ff7eba08c8', '84fc4faf-c336-4b9a-a295-c49c71e71b83', 'de61bce6-ac17-40c5-ad2d-98635c0ad896', '6684af99-5494-4d2e-83a9-584cd45a08db', '1d3b3195-aee1-4c22-9a75-e4c9e476df31', '2d89ccdc-c1ea-4199-92e1-cc4e047434a3', 'ceb71761-716b-4468-8183-bd08ef52193b', 'cedba2f3-b7f9-4476-9a4e-41d61d4d55ba', 'e872be73-13cb-46a6-83ab-b34a32fbd684', '15227c54-35ff-461b-829b-04bf9d1e5354', '85786f7b-a003-4541-b20f-acc4a1734ba3', 'b64d0b0c-1a91-4b77-b184-7e57d89a83b8', 'f9490aa2-ea2c-472f-acd7-51f440c605be', 'c19bee8a-2a93-4741-abcc-2c6919d2e6c8', '404524bc-37e7-4925-938d-135316dfc157', '67c06bbe-4dc8-4997-bb6f-e67c6921d2ca', '1175b6d5-f2e4-4fc3-b80f-9c996e06b1fa', '74af88be-fb3a-4729-b69a-54b3e7f09f6d', '67321977-6657-44f5-a8dd-dfff49bb94b7'],
  'Pre-Draft': ['1d996555-92f2-44a3-ad2b-ac60e2a78fc6', '2fdb545b-6a48-4401-a5eb-4034b2995ee0', '6503183a-f8ca-4cf0-b0fb-48fead41d769', 'a2db6303-7e2d-419e-91aa-7da486ce2b02', '422038fd-93e2-4c67-a460-e5facc1bdced', '4eb0d173-f561-42bb-9c52-39c3531b9a86', 'f66bc308-bced-4444-b172-977958215ab4', 'bf0575ec-4089-4091-9e7b-850f61891ee9', '83eddf47-443a-461a-acfd-0f7b5ac591e2', '0360bf08-dd43-42f9-8550-cde9aef5b866', '349e907e-e567-4057-9d80-795746d7c6a6', '04d1ac15-139e-4cd8-851c-1d012052e218', '2c81e528-d5ef-4a8e-b4ad-8ce2473ed5c2', '6645a4d3-982e-46e3-85c0-9ee8c02c960f', '536984ff-799a-482a-b05f-474371dbe4d0', '58ba7c68-9c3e-426e-bc55-5f4886a51271', '281b1a6a-01d3-4e05-9b60-246f368b992c', 'c55c2457-839a-4876-81ba-13879b20f78b', 'd35f5475-cf67-44c1-9188-75cc60c1abc9', 'dc9f2658-d0ce-477a-8c1f-d0b70c3a3c25'],
}

export function getUnderdogRosterType(dataset: Array<iUdPickRaw>): iUdTypes {
  const data: Array<string> = dataset.filter(p => p.pick_no < 24).map(p => p.id_udc)
  for (const [key, sampleArray] of Object.entries(udPlayerIdsSample)) {
    if (data.some(item => sampleArray.includes(item))) {
      return key as iUdTypes
    }
  }
  throw new Error('Roster type not found')
}

export function calcUdRostershipPositionRoundDistribution(dataset: Array<iUdPick>): Array<{ [key: string]: number }> {
  // const rostership: Record<number, Record<string, number>> = {}
  const result: Array<{ [key: string]: number }> = []
  for (let i = 1; i <= 20; i++) {
    const data = dataset.filter(p => p.pick_no > (i - 1) * 12 && p.pick_no <= i * 12)
    const total = data.length
    const playerGroups: Record<string, number> = {}
    for (const position of ['QB', 'RB', 'WR', 'TE']) {
      const count = data.filter(p => p?.id_pos === position).length
      playerGroups[position] = count
      playerGroups[`${position}_perc`] = roundNumber(count / total, 2)
    }
    playerGroups.round = i
    result.push(playerGroups)
    // rostership[i] = playerGroups
  }
  // return Object.entries(rostership).map(([key, value]) => ({ round: key, ...value }))
  return result
}

export function calcUdRostershipPositionStructure(dataset: Array<iUdPick>) {
  const rosters = groupByAttribute(dataset, 'id_draft')
  const result: Record<string, number> = {}
  for (const roster in rosters) {
    const dataset = rosters[roster]
    const distribution: Record<string, number> = { QB: 0, TE: 0, RB: 0, WR: 0 }
    dataset.forEach((pick) => {
      if (pick.id_pos)
        distribution[pick.id_pos]++
    })
    const metric = JSON.stringify(distribution)
    if (!result[metric])
      result[metric] = 0
    result[metric]++
  }
  const maxDrafts = Math.max(...Object.values(result))
  for (const key of Object.keys(result))
    result[`${key}_perc`] = roundNumber(result[key] / maxDrafts, 2)
  return result
}

export function calcUdRostershipPickDistribution(dataset: Array<iUdPick>) {
  const result: Record<number, number> = {}
  const data = dataset.filter(p => p.pick_no <= 12)
  for (let i = 1; i <= 12; i++) {
    result[i] = 0
  }
  for (const pick of data) {
    if (pick?.pick_no)
      result[pick.pick_no]++
  }
  const maxDrafts = Math.max(...Object.values(result))
  return Object.entries(result).map(([key, value]) => ({
    value,
    pick_no: key,
    value_perc: roundNumber(value / maxDrafts, 2),
  }))
}

export function calcUdRostershipDateDistribution(dataset: Array<iUdPick>) {
  const now = DateTime.now()
  const startDate = now.minus({ months: 1 }).startOf('week')
  const weekCounts = new Map<string, number>()

  for (let week = startDate; week <= now; week = week.plus({ weeks: 1 })) {
    weekCounts.set(week.toFormat('MM/dd'), 0)
  }

  for (const pick of dataset) {
    if (pick.pick_no > 12)
      continue
    const weekStart = typeof pick.pick_date === 'string' ? DateTime.fromISO(pick.pick_date).startOf('week') : DateTime.fromJSDate(pick.pick_date).startOf('week')
    const weekKey = weekStart.toFormat('MM/dd')
    weekCounts.set(weekKey, (weekCounts.get(weekKey) || 0) + 1)
  }
  const maxDrafts = Math.max(...weekCounts.values())

  return Array.from(weekCounts, ([startDate, count]) => {
    const start = DateTime.fromFormat(startDate, 'MM/dd')
    const end = start.plus({ days: 6 })
    return {
      week: `${startDate} - ${end.toFormat('MM/dd')}`,
      value: count,
      value_perc: roundNumber(count / maxDrafts, 2),
    }
  }).sort((a, b) => a.week.localeCompare(b.week))
}

export function calcUdRostershipPositionAllocation(dataset: Array<iUdPick>): Array<{ [key: string]: number }> {
  const rosters = groupByAttribute(dataset, 'id_draft')
  const total = Object.keys(rosters).length
  const result: Record<number, Record<string, number>> = {}
  for (let i = 1; i <= 14; i++)
    result[i] = { QB: 0, QB_perc: 0, RB: 0, RB_perc: 0, WR: 0, WR_perc: 0, TE: 0, TE_perc: 0 }
  for (const roster in rosters) {
    const dataset = rosters[roster]
    for (const position of ['QB', 'RB', 'WR', 'TE']) {
      const metric = dataset.filter(p => p?.id_pos === position).length
      const value = (result[metric][position] || 0) + 1
      result[metric][position] = value
      result[metric][`${position}_perc`] = roundNumber(value / total, 2)
    }
  }
  return Object.entries(result).map(([key, value]) => ({
    players: getIntValue(key),
    ...value,
  }))
}

export function getSortedTeams() {
  return ['BUF', 'MIA', 'NYJ', 'NE', 'BAL', 'CLE', 'PIT', 'CIN', 'HOU', 'JAX', 'IND', 'TEN', 'KC', 'LV', 'DEN', 'LAC', 'DAL', 'PHI', 'NYG', 'WAS', 'DET', 'GB', 'MIN', 'CHI', 'TB', 'NO', 'ATL', 'CAR', 'SF', 'LAR', 'SEA', 'ARI']
}

export function calcUdTeamRosters(dataset: Array<iPlayerUd>) {
  const teams = getSortedTeams()
  const teamRosters: Record<string, Record<string, iPlayerUd>> = {}
  for (const team of teams) {
    if (!team)
      continue
    const teamPlayers = dataset.filter(p => p.id_team === team)
    teamRosters[team] = {}
    const positionCount: Record<string, number> = {}

    for (const player of teamPlayers) {
      const position = player.id_pos
      if (!position)
        continue

      const count = (positionCount[position] = (positionCount[position] || 0) + 1)
      teamRosters[team][`${position}${count}`] = player
    }
  }
  return teamRosters
}

export function calcUdRostershipStackTeamMates(dataset: Array<iUdPick>, teamRosters: Record<string, Record<string, iPlayerUd>>) {
  // const teams = [...new Set(dataset.map(p => p.id_team))].filter(Boolean)
  const teams = getSortedTeams()
  const results: iUdStackInformation[] = []
  for (const team of teams) {
    if (!team)
      continue
    const { w15team, w16team, w17team } = scheduleOpponents.get(team)!
    const opponents = {
      w15opp: 0,
      w16opp: 0,
      w17opp: 0,
    }
    const teamPlayers = dataset.filter(p => p.id_team === team)
    const drafts = teamPlayers.filter(p => p.id_pos === 'QB').map(p => p.id_draft)
    const total = drafts.length
    const stacks: Record<string, number> = {}
    for (const draft of drafts) {
      const draftPlayers = dataset.filter(p => p.id_draft === draft)
      const teamMates = draftPlayers.filter(p => p.id_pos !== 'QB' && p.id_team === team)
      // if (draftPlayers.filter(p => p.id_pos !== 'QB' && p.id_team === w15team).length === 0)
      if (draftPlayers.filter(p => p.id_team === w15team).length > 0)
        opponents.w15opp++
      // if (draftPlayers.filter(p => p.id_pos !== 'QB' && p.id_team === w16team).length === 0)
      if (draftPlayers.filter(p => p.id_team === w16team).length > 0)
        opponents.w16opp++
      if (draftPlayers.filter(p => p.id_team === w17team).length > 0)
        opponents.w17opp++
      const metric = `stack${teamMates.length}`
      if (!stacks[metric])
        stacks[metric] = 0
      stacks[metric]++
    }
    const { id_pff, ud_adp, ud_adp_perc, id_name } = teamRosters[team]?.QB1 || { id_pff: '', ud_adp: 0, id_name: '' }
    const w15opp = total ? (opponents.w15opp / total) : 0
    const w16opp = total ? (opponents.w16opp / total) : 0
    const w17opp = total ? (opponents.w17opp / total) : 0
    results.push({
      id_pff: id_pff!,
      id_name: id_name!,
      ud_adp,
      ud_adp_perc,
      total,
      team,
      w15team,
      w16team,
      w17team,
      ...stacks,
      w15opp,
      w15opp_perc: w15opp,
      w16opp,
      w16opp_perc: w16opp,
      w17opp,
      w17opp_perc: w17opp,
    })
  }
  const max = Math.max(...results.map(p => p.total))
  for (const result of results) {
    result.total_perc = roundNumber(result.total / max, 2)
    for (const i of [0, 1, 2, 3, 4, 5]) {
      if (result[`stack${i}`])
        result[`stack${i}_perc`] = roundNumber(result[`stack${i}`] / result.total, 2)
    }
  }
  return results
}

export function calcUdRostershipConstructionType(dataset: Array<iUdPick>) {
  const rosters = groupByAttribute(dataset, 'id_draft')
  const total = Object.keys(rosters).length
  const construction: Record<string, Record<string, iUdRosterContructionMetric>> = {
    QB: {
      elite: { value: 0, title: 'Elite QB', description: 'QB1 <= Round 5', percentile: 0, percent: 0 },
      mid1: { value: 0, title: '1 Mid QB', description: 'QB1 >= Round 6 & QB2 > Round 12', percentile: 0, percent: 0 },
      mid2: { value: 0, title: '2 Mid QB', description: 'QB1 >= Round 6 & QB2 <= Round 12', percentile: 0, percent: 0 },
      mid3: { value: 0, title: '3 Mid QB', description: 'QB1 >= Round 6 & QB3 <= Round 12', percentile: 0, percent: 0 },
      punt: { value: 0, title: 'Punt QB', description: 'QB1 >= Round 11', percentile: 0, percent: 0 },
    },
    RB: {
      zero: { value: 0, title: 'Zero RB', description: 'RB1 > Round 7', percentile: 0, percent: 0 },
      anchor: { value: 0, title: 'Anchor RB', description: 'RB1 <= Round 3 & RB2 > Round 6', percentile: 0, percent: 0 },
      anchor2: { value: 0, title: '2 Anchor RB', description: 'RB2 <= Round 3 & RB3 > Round 6', percentile: 0, percent: 0 },
      mid: { value: 0, title: 'Mid RB', description: 'RB1 between 4 and 6 & RB2 > Round 6', percentile: 0, percent: 0 },
      fragile: { value: 0, title: 'HyperFragile', description: 'RB3 <= Round 10 (Max 4 RB)', percentile: 0, percent: 0 },
      robust: { value: 0, title: 'Robust RB', description: 'RB4 <= Round 7', percentile: 0, percent: 0 },
    },
    TE: {
      elite: { value: 0, title: 'Elite TE', description: 'TE1 <= Round 5', percentile: 0, percent: 0 },
      bully: { value: 0, title: 'Bully TE', description: 'TE2 <= Round 6', percentile: 0, percent: 0 },
      mid: { value: 0, title: 'Mid TE', description: 'TE1 between Round 6 and 9 ', percentile: 0, percent: 0 },
      late3: { value: 0, title: '3 Late TE', description: '3 TE between round 9 and 15', percentile: 0, percent: 0 },
      punt: { value: 0, title: 'Punt TE', description: 'TE1 >= Round 10', percentile: 0, percent: 0 },
    },
    WR: {
      wr4r7: { value: 0, title: '4 WR by R7', description: 'WR4 <= Round 7', percentile: 0, percent: 0 },
      wr5r10: { value: 0, title: '5 WR by R10', description: 'WR5 <= Round 10', percentile: 0, percent: 0 },
    },
  }
  for (const roster in rosters) {
    const players = rosters[roster].sort(sortByAttribute('pick_no'))

    const { QB, RB, WR, TE } = groupByAttribute(players, 'id_pos')
    if (QB && QB.length > 0) {
      if (QB[0].pick_no <= 60)
        construction.QB.elite.value++
      if (QB[1] && QB[0].pick_no > 60 && QB[1].pick_no > 144)
        construction.QB.mid1.value++
      if (QB[1] && QB[0].pick_no > 60 && QB[1].pick_no <= 144)
        construction.QB.mid2.value++
      if (QB[2] && QB[0].pick_no >= 60 && QB[2].pick_no <= 144)
        construction.QB.mid3.value++
      if (QB[0].pick_no > 108)
        construction.QB.punt.value++
    }
    if (RB && RB.length > 0) {
      if (RB[4] && RB[3].pick_no <= 84)
        construction.RB.robust.value++
      if (RB[3] && !RB[4] && RB[2].pick_no <= 120)
        construction.RB.fragile.value++
      if (RB[2] && RB[1].pick_no <= 36 && RB[2].pick_no >= 72)
        construction.RB.anchor2.value++
      if (RB[1] && RB[0].pick_no > 36 && RB[0].pick_no < 72 && RB[1].pick_no >= 72)
        construction.RB.mid.value++
      if (RB[2] && RB[0].pick_no <= 36 && RB[1].pick_no >= 72)
        construction.RB.anchor.value++
      if (RB[0].pick_no > 72)
        construction.RB.zero.value++
    }
    if (TE && TE.length > 0) {
      if (TE[0].pick_no <= 60)
        construction.TE.elite.value++
      if (TE[1] && TE[1].pick_no <= 72)
        construction.TE.bully.value++
      if (TE[1] && TE[0].pick_no > 60 && TE[1].pick_no <= 108)
        construction.TE.mid.value++
      if (TE[2] && TE[0].pick_no > 108 && TE[2].pick_no <= 180)
        construction.TE.late3.value++
      if (TE[0].pick_no >= 108)
        construction.TE.punt.value++
    }
    if (WR && WR.length > 0) {
      if (WR[3] && WR[3].pick_no <= 84)
        construction.WR.wr4r7.value++
      if (WR[4] && WR[4].pick_no <= 120)
        construction.WR.wr5r10.value++
    }
  }

  for (const position in construction) {
    for (const metric in construction[position]) {
      construction[position][metric].percentile = normalizeValue(construction[position][metric].value, 0, total * 60 / 100)
      construction[position][metric].percent = construction[position][metric].value / total
    }
  }
  return construction
}

export function calcUdRostershipAdpValues(dataset: Array<iUdPick>, adpDataset: Array<iPlayerUd>): Array<iUdDraftValues> {
  const drafts = new Set(dataset.map(d => d.id_draft))
  const adpMap = new Map(adpDataset.map(p => [p.id_ud, p]))
  return [...drafts].map((draft) => {
    const players = dataset.filter(p => p.id_draft === draft)
    const stackTeams = players.filter(p => p.id_pos === 'QB').map(p => p.id_team).filter(Boolean)
    const stackedPlayers = players.filter(p => p.id_pos !== 'QB' && stackTeams.includes(p.id_team)).length
    const adpValue = roundNumber(players.map(p => MapDictionary.BbAdpValues.get(roundNumber(adpMap.get(p.id_ud)?.ud_adp || 240, 0))).reduce((a, b) => (a || 0) + (b || 0), 0), 1)
    const totalValue = roundNumber(players.map(p => MapDictionary.BbAdpValues.get(p.pick_no) || 0).reduce((a, b) => (a || 0) + (b || 0), 0), 1)
    const diffValue = roundNumber(adpValue - totalValue, 1)
    return {
      stackedPlayers,
      adpValue,
      totalValue,
      diffValue,
      cost: players[0]?.tournament_fee || 0,
      tournament: players[0]?.tournament_name || '',
      date: genLuxonDateObject(players[0]?.pick_date),
    }
  }).sort(sortByAttribute('date', 'desc'))
}

export function calcUdRostershipAdpReaches(dataset: Array<iUdPick>, adpDataset: Array<iPlayerUd>): Array<iPlayerUdRosterReaches> {
  const adpMap = new Map(adpDataset.map(p => [p.id_ud, p]))
  const playerGroups = groupByAttribute(dataset.filter(p => adpMap.has(p.id_ud)), 'id_ud')
  const maxCount = Math.max(...Object.values(playerGroups).map(group => group.length))
  const result = Object.entries(playerGroups).map(([id_ud, players]) => {
    const player = adpMap.get(id_ud)!
    const adpValue = MapDictionary.BbAdpValues.get(roundNumber(player?.ud_adp || 240, 0))
    // const totalDiff = players.map(p => player.ud_adp - p.pick_no).reduce((a, b) => (a || 0) + (b || 0), 0)
    const totalDiffValue = players.map(p => adpValue - MapDictionary.BbAdpValues.get(p.pick_no)).reduce((a, b) => (a || 0) + (b || 0), 0)
    const count = players.length
    const percValue = normalizeValue(count, 0, maxCount, true)
    const timesReach = players.filter(p => p.pick_no <= player.ud_adp).length
    const timesFall = count - timesReach
    return {
      ...player,
      // totalDiff: roundNumber(totalDiff, 2),
      totalDiffValue: roundNumber(totalDiffValue, 2),
      roster_count: count,
      roster_count_perc: percValue,
      timesReach,
      timesFall,
    }
  })
  // const minmaxDiff = extractMinMax(result.map(d => d.totalDiff))
  const minmaxDiffValue = extractMinMax(result.map(d => d.totalDiffValue))
  const minmaxReach = extractMinMax(result.map(d => d.timesReach))
  const minmaxFall = extractMinMax(result.map(d => d.timesFall))

  return result.map(d => ({
    ...d,
    // totalDiff_perc: normalizeValue(d.totalDiff, minmaxDiff[0], minmaxDiff[1]),
    totalDiffValue_perc: normalizeValue(d.totalDiffValue, minmaxDiffValue[0], minmaxDiffValue[1]),
    timesReach_perc: normalizeValue(d.timesReach, minmaxReach[0], minmaxReach[1]),
    timesFall_perc: normalizeValue(d.timesFall, minmaxFall[0], minmaxFall[1]),
  })).sort(sortByAttribute('totalDiffValue', 'asc'))
}

export function calcUdRostershipInsights(dataset: Array<iUdPick>, adpDataset: Array<iPlayerUd>) {
  const teamRosters = calcUdTeamRosters(adpDataset)
  return {
    pickDistribution: calcUdRostershipPickDistribution(dataset),
    dateDistribution: calcUdRostershipDateDistribution(dataset),
    positionRoundDistribution: calcUdRostershipPositionRoundDistribution(dataset),
    positionAllocation: calcUdRostershipPositionAllocation(dataset),
    positionStructure: calcUdRostershipPositionStructure(dataset),
    teamRosters,
    stackTeamMates: calcUdRostershipStackTeamMates(dataset, teamRosters),
    rosterConstruction: calcUdRostershipConstructionType(dataset),
    adpValues: calcUdRostershipAdpValues(dataset, adpDataset),
    adpReaches: calcUdRostershipAdpReaches(dataset, adpDataset),
  }
}
